<template>
  <div id="app" @dragstart.prevent >
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "App",

  mounted() {
    document.oncontextmenu = function (e) {
      return false;
      //或者 e.preventDefault()
    };
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  },
};
</script>

<style>
*{ user-select:none; -webkit-user-select:none; }

input,textarea{ user-select:text; -webkit-user-select:text; }
@media all and (min-width: 0px) {
  * {
    font-size: 1vw;
  }
}
@media all and (min-width: 481px) {
  * {
    font-size: 1vw;
  }
}
@media all and (min-width: 569px) {
  * {
    font-size: 1vw;
  }
}
@media all and (min-width: 700px) {
  * {
    font-size: 1vw;
  }
}
@media all and (min-width: 1000px) {
  * {
    font-size: 1vw;
  }
}
@media all and (min-width: 1440px) {
  * {
    font-size: 1vw;
  }
}

body {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #f2f5ff;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;
}
</style>
