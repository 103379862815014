import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const router = new VueRouter({
    routes: [{
        path: "/",
        component: () =>
            import("../views/homePage"),
    },
    {
        path: "/server",
        component: () =>
            import("../views/serverPage"),
    },
    {
        path: "/case",
        component: () =>
            import("../views/casePage"),
    },
    {
        path: "/detail",
        component: () =>
            import("../views/detail"),
    },
    {
        path: "/about",
        component: () =>
            import("../views/aboutPage"),
    },
    {
        path: "/mdetail",
        component: () =>
            import("../views/mdetailPage"),
    },
    {
        path: "/mdetailin",
        component: () =>
            import("../views/detailm"),
    },
    {
        path: '*',
        component: () =>
            import("../views/404"),
    },
    {
        path: '/demofs',
        component: () =>
            import("../views/demofs"),
    },
    {
        path: '/demo',
        component: () =>
            import("../views/demo1"),
    },
    {
        path: '/demo2',
        component: () =>
            import("../views/demo2"),
    }
        ,
    {
        path: '/demo4',
        component: () =>
            import("../views/demo4"),
    }
    ],
});
export default router;