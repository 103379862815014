import Vue from "vue";
import App from "../src/App.vue";
import router from "./router";
import ElementUI from "element-ui";
import Vant from "vant";
import axios from "axios";
import { deptId, baseUrl } from "@/utils/config.js";

import { Lazyload } from 'vant';
import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);

Vue.use(Lazyload);
// 全局方法挂载
import "vant/lib/index.css";
import "animate.css";
import "element-ui/lib/theme-chalk/index.css";
Vue.prototype.axios = axios;
Vue.prototype.deptId = deptId;
Vue.prototype.baseUrl = baseUrl;
Vue.use(ElementUI);
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
    beforeCreate() {
        Vue.prototype.$bus = this
    }
}).$mount("#app");